<template>
<div class="judgeExamine">
    <Tabs class="judge-tabs" v-model="activeName" title-active-color="#000" v-on:click="Loadmore()">
            <tab  :title="'审核中 '+shenhezhong" name="a"></tab>
            <tab  :title="'已通过 '+yitongguo" name="b"></tab>
            <tab  :title="'未通过 '+weitongguo" name="c"></tab>
    </Tabs>
    <div class="tabs-content">
            <div v-if="activeName=='a'">
                <div class="itemExamine clearfix" v-for="(item,index) in examine.list" :key="index">
                    <div class="pic" @click="goPage()">
                        <img :src="item.simage" mode="aspectFill" />
                    </div>
                    <div class="con" @click="goPage()">
                        <div class="tit Ellipses3">{{item.content}}</div>
                        <div class="time">{{item.time}}</div>
                    </div>
                    <!-- <div class="switch"><ToggleSwitch ref="ToggleSwitch" :speed='item.is_show?0:1' @change.stop="isShows(item.id)"></ToggleSwitch></div> -->
                </div>
                <div class="Loading" v-if="examine.loading">
                    <Loading size="24px">加载中...</Loading>
                </div>
                <div class="Loading" v-if="examine.finished">没有更多了~</div>
            </div>
            <div v-if="activeName=='b'">
                <div class="itemExamine clearfix" v-for="(item,index) in judge.list" :key="index">
                    <div class="pic" @click="goPage()">
                        <img :src="item.simage" mode="aspectFill" />
                    </div>
                    <div class="con" @click="goPage()">
                        <div class="tit Ellipses3">{{item.content}}</div>
                        <div class="time">{{item.time}}</div>
                    </div>
                    <div class="switch"><ToggleSwitch ref="ToggleSwitch" :speed='item.is_show?0:1' @change="switchStatus(item.id)"></ToggleSwitch></div>
                </div>
                <div class="Loading" v-if="judge.loading">
                    <Loading size="24px">加载中...</Loading>
                </div>
                <div class="Loading" v-if="judge.finished">没有更多了~</div>
            </div>
            <div  v-if="activeName=='c'" >
                <div class="itemExamine-box" v-for="(item,index) in unexamine.list" :key="index">
                    <SwipeCell slot="left" :right-width="140"  class="itemExamine clearfix" >
                        <Cell :border="false">
                        <div class="pic" >
                            <img :src="item.simage" mode="aspectFill" />
                        </div>
                        <div class="con">
                            <div class="tit Ellipses3">{{item.content}}</div>
                            <div class="time">{{item.time}}</div>
                        </div>
                        <div class="caozuo" >左滑操作</div>
                        </Cell>
                        <div slot="right" class="bit-box">
                            <div class='edit-bit' @click.stop="goPage('/Tasting/wine/Release?judgeid='+item.id)">编辑</div>
                            <div class="del-bit" @click.stop="del(item.id)">删除</div>
                        </div>
                    </SwipeCell>
                    <div class="res" v-if="item.reason" style="color:#f00;padding:0.2rem">备注：{{item.reason}}</div>
                    <div class="res" v-if="!item.reason" style="color:#f00;padding:0.2rem">备注：评论不符合平台规范，请修改后重新提交！</div>
                </div>
                <div class="Loading" v-if="unexamine.loading">
                    <Loading size="24px">加载中...</Loading>
                </div>
                <div class="Loading" v-if="unexamine.finished">没有更多了~</div>
            </div>
        </div>
        <Judge></Judge>
        <Backtop></Backtop>
</div>
</template>
<script>
    import { JudgeUserList,JudgeStatusCount,switchJudgeStatus,delJudge } from "@api/tasting";
    import Judge from "../../components/Judge";
    import Backtop from "../../components/Backtop";
    import ToggleSwitch from '../../components/ToggleSwitch.vue';
    import { Icon, Toast, Tab, Tabs ,Loading ,SwipeCell,Cell,Button} from "vant";
    export default {
        name: "judgeExamine",
        data: function() {
            return {
                activeName:'a',
                shenhezhong:0,
                yitongguo:0,
                weitongguo:0,
                keyword:'',
                judge:{
                    page:1,
                    limit:8,
                    finished:false,
                    loading:false,
                    list:[]
                },
                examine:{
                    page:1,
                    limit:8,
                    finished:false,
                    loading:false,
                    list:[]
                },
                unexamine:{
                    page:1,
                    limit:8,
                    finished:false,
                    loading:false,
                    list:[]
                },
            };
        },
        components: {
            Icon, Toast, Tab, Tabs ,Loading ,Judge ,Backtop,ToggleSwitch ,SwipeCell,Cell,Button
        },
        created: function() {
            
        }, 
        mounted: function() {
           this.JudgeCount();
           this.Loadmore();
            var that=this;
            window.addEventListener("scroll", function(event) {
                var scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
                if(document.documentElement.scrollHeight == document.documentElement.clientHeight + scrollTop) {
                    that.Loadmore();
                }
            });
        },
        methods: {
            goPage(url){
                if(url){
                    this.$router.push({ path: url });
                }else{
                    Toast({
                        message: '努力开发中，敬请期待',
                        forbidClick: true,
                    });
                }
            },
            JudgeCount(){
                JudgeStatusCount().then(res=>{
                    this.shenhezhong=res.data.examineCount;
                    this.yitongguo=res.data.judgeCount;
                    this.weitongguo=res.data.unexamineCount;
                })
            },
            switchStatus(itemid){
                Toast.loading({
                    message: '切换中',
                    forbidClick: true,
                });
                switchJudgeStatus({
                    id:itemid,
                }).then(res=>{
                    Toast.clear();
                    Toast.success({
                        message: '切换成功',
                        forbidClick: true,
                    });
                }).catch(err => {
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                })
            },
            Loadmore: function() {
                if(this.activeName=='a'){
                    if(this.examine.loading)return;
                    if(this.examine.finished)return;
                    this.judgeList(0);  
                }else if(this.activeName=='b'){
                    if(this.judge.loading)return;
                    if(this.judge.finished)return;
                    this.judgeList(1);  
                }else{
                    if(this.unexamine.loading)return;
                    if(this.unexamine.finished)return;
                    this.judgeList(2);  
                }  
            },
            //删除
            del(itemid,index){
                delJudge({
                    id:itemid,
                }).then(res=>{
                    this.unexamine.list.splice(index, 1);
                    this.$set(this.unexamine, "list",  this.unexamine.list);
                    this.weitongguo -= this.weitongguo; 
                    if(this.weitongguo<0)this.weitongguo = 0;
                    Toast.success({
                        message: res.msg,
                        forbidClick: true,
                    });
                }).catch(err=>{
                     Toast.fail({
                        message: res.msg,
                        forbidClick: true,
                    });
                })
            },
            //列表
            judgeList(type){
                var pages = 0;
                var limits = 0;
                if(type==1){
                    this.judge.loading = true;
                    pages=this.judge.page;
                    limits=this.judge.limit;
                 }else if(type==2){
                    this.unexamine.loading = true;
                    pages=this.judge.page;
                    limits=this.judge.limit;
                 }else{
                    this.examine.loading = true;
                    pages=this.judge.page;
                    limits=this.judge.limit;
                 }
                JudgeUserList({
                    page:pages,
                    limit:limits,
                    keyword:this.keyword,
                    status:type,
                }).then(res => {
                    //this.yitongguo =res.data.count;
                    var listdata = res.data.list;
                    if(type==1){
                        listdata.forEach((item)=>{
                        this.judge.list.push(item);
                        })
                        this.judge.loading = false;
                        this.judge.page++;
                        if(listdata.length<10){
                            this.judge.finished = true;
                        }
                    }else if(type==2){
                        listdata.forEach((item)=>{
                        this.unexamine.list.push(item);
                        })
                        this.unexamine.loading = false;
                        this.unexamine.page++;
                        if(listdata.length<10){
                            this.unexamine.finished = true;
                        }
                    }else{
                        listdata.forEach((item)=>{
                        this.examine.list.push(item);
                        })
                        this.examine.loading = false;
                        this.examine.page++;
                        if(listdata.length<10){
                            this.examine.finished = true;
                        }
                    }
                }).catch(err => {
                    if(type==1){
                        this.judge.loading = false;
                    }else if(type==2){
                        this.unexamine.loading = false;
                    }else{
                        this.examine.loading = false;
                    }
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
            
            
        
        }
    
}
</script>
<style lang="scss">
    .Loading{text-align: center;padding:0.2rem;font-size:0.28rem;color:#999;}
    .judgeExamine .van-tabs{position:fixed;top:0;width: 100%;z-index: 100;}
    .itemExamine-box{border-bottom: 1px solid #eee;}
    .itemExamine{background: #fff;padding: 0.2rem 0rem;position: relative;margin-top: 11%;}
    .itemExamine .pic{float: left;width: 2rem;height: 2rem;margin-right: 0.5rem;border-radius: 0.2rem;overflow: hidden;}
    .itemExamine .pic img{width: 100%;height: 100%;}
    .itemExamine .con{float: left;width: 3rem;}
    .itemExamine .con .tit{height: 1.1rem;margin-top: 0.1rem;}
    .itemExamine .con .time{color: #999;font-size: 0.2rem;margin-top: 0.3rem;}
    .itemExamine .switch{position: absolute;bottom: 0.2rem;right: 0.1rem;width: 1rem;/*height: 0.4rem;*/}
    .itemExamine .status{position: absolute;top: 0.3rem;right: 0.2rem;font-size: 0.2rem;color: #999;}
    
    .itemExamine .bit-box{
        display: flex;
         height: 100%;
         align-items:center;
         justify-content:center;
         text-align: center;
        div{
            padding: 34% 0px;
            font-weight: 600;
            color: #fff;
            margin-top: 0.2rem;
            width: 70px;
        }
        .edit-bit{
            background-color: rgb(54, 228, 228);
            
        }
        .del-bit{
            background-color: rgb(231, 53, 53);
        }
    }
    .itemExamine .caozuo{
        writing-mode:vertical-lr;
        position: absolute;
        padding-top: 0.6rem;
        right: 0rem;
        font-size: 0.2rem;
        color: #ccc;
        //-webkit-animation: flicker 3s linear infinite;
        //animation: flicker 3s linear infinite;
        //text-shadow: -1px -1px 0 rgba(255, 255, 255, 0.4), 1px -1px 0 rgba(255, 255, 255, 0.4), -1px 1px 0 rgba(255, 255, 255, 0.4), 1px 1px 0 rgba(255, 255, 255, 0.4), 0 -2px 8px, 0 0 2px, 0 0 5px #eee, 0 0 15px #ccc, 0 0 2px #eee, 0 2px 3px #000;
    }
//     @-webkit-keyframes flicker {
//     0%, 19.999%, 22%, 62.999%, 64%, 64.999%, 70%, 100% {
//         opacity: .99;
//         text-shadow: -1px -1px 0 rgba(255, 255, 255, 0.4), 1px -1px 0 rgba(255, 255, 255, 0.4), -1px 1px 0 rgba(255, 255, 255, 0.4), 1px 1px 0 rgba(255, 255, 255, 0.4), 0 -2px 8px, 0 0 2px, 0 0 5px #eee, 0 0 15px #ccc, 0 0 2px #eee, 0 2px 3px #000;
//     }
//     20%,
//     21.999%,
//     63%,
//     63.999%,
//     65%,
//     69.999% {
//         opacity: 0.4;
//         text-shadow: none;
//     }
// }
// @keyframes flicker {
//     0%, 19.999%, 22%, 62.999%, 64%, 64.999%, 70%, 100% {
//         opacity: .99;
//         text-shadow: -1px -1px 0 rgba(255, 255, 255, 0.4), 1px -1px 0 rgba(255, 255, 255, 0.4), -1px 1px 0 rgba(255, 255, 255, 0.4), 1px 1px 0 rgba(255, 255, 255, 0.4), 0 -2px 8px, 0 0 2px, 0 0 5px #eee, 0 0 15px #ccc, 0 0 2px #eee, 0 2px 3px #000;
//     }
//     20%,
//     21.999%,
//     63%,
//     63.999%,
//     65%,
//     69.999% {
//         opacity: 0.4;
//         text-shadow: none;
//     }
// }
</style>