<template>
	<label role="checkbox" :class="['switch', { toggled }]">
	    <input type="checkbox"
	           class="switch-input"
	           @change="toggle"/>
	    <div class="switch-core"
	         :style="{backgroundColor: toggled ? colorUnchecked  : colorChecked}">
	      <div class="switch-button"
	           :style="{transition: `transform ${speed}ms`,
	           transform: toggled ? null : `translate3d(20px, 0px, 0px)`,border: toggled ? `1px solid #dadada` : `1px solid #ff0000;`}">
	      </div>
	    </div>
	    <span class="switch-label label-right"
	          v-if="toggled"
	          v-html="labelUnchecked">
	     </span>
	    <span class="switch-label label-left"
	          v-html="labelChecked" v-else>
	    </span>
	  </label>
</template>
 
<script>
import { dtShow } from "@api/tasting";
	export default {
	    name: 'ToggleSwitch',
	    data () {
	      return {
	        toggled: this.speed,
	        colorChecked: '#e93323',
	        colorUnchecked: '#f5f5f5',
	        //labelChecked: '开',
	        //labelUnchecked: '关'
	      }
	    },
	    props: {
	      value: {
	        type: Boolean,
	        default: true
	      },
	      speed: {
	        type: Number,
	        default: 1
	      }
	    },
      mounted: function() {
        console.log(this.speed);
      },
	    methods: {
	      toggle (event) {
	        this.toggled = !this.toggled
	        this.$emit('change', this.toggled)
	      }
	    }
	  }
</script>
 
<style scoped >
  .switch {
  	float: right;
    display: inline-block;
    position: relative;
    overflow: hidden;
    vertical-align: middle;
    user-select: none;
    font-size: 10px;
    cursor: pointer;
    border-radius: 15px;
    margin-top: 10px;
  }
    .switch-input {
      display: none;
    }
 
    .switch-label {
      position: absolute;
      top: 0;
      font-weight: 600;
      color: white;
 
      z-index: 2;
    }
      .label-left {
        left: 10px;
        line-height: 25px;
        border-top-left-radius: 2px;
        border-bottom-left-radius:2px;
      }
  
      .label-right {
        right: 10px;
        line-height: 25px;
        border-top-right-radius: 2px;
        border-bottom-right-radius:2px;
      }

 
    .switch-core {
      display: block;
      position: relative;
      box-sizing: border-box;
      outline: 0;
      margin: 0;
      transition: border-color .3s, background-color .3s;
      user-select: none;
      width: 50px;
      height: 30px;
      line-height: 25px;
 	    border-radius: 30px;
       text-shadow: 5px 5px 5px #FF0000;
    }
      .switch-button {
        width: 30px;
        height: 30px;
        display: block;
        position: absolute;
        overflow: hidden;
        border-radius: 30px;
        top: 0;
        left: 0;
        z-index: 3;
        transform: translate3d(0, 0, 0);
        background-color: #ecf0f5;
      }
</style>