<template>
    <a href="javascript:void(0);" class="release" @click.prevent="onSelect('/Tasting/wine/Release')">
      <div class="iconfont iconxiangji border-color font-color"></div>
      <div class="fabu-font">发表</div>
    </a>
    <!-- <ActionSheet v-model="show" :actions="actions" description="选择您要发布的内容" /> -->
</template>

<script>
    export default {
        name: "Judge",
        data: function() {
            return {
                id:'',
            };
        },
        mounted: function() {
        },
        methods: {
           onSelect(item) {
            this.show = false;
            if(this.$route.query.id){
                this.$router.push({ path: item+'?id='+this.$route.query.id});
            }else{
                this.$router.push({ path: item});
            }
            },
        }
    }
</script>

<style scoped lang="scss">
// .backtop{
//     position: fixed;
//     right: 0.3rem;
//     bottom: 2rem;
//     z-index: 100;
//     width: 0.8rem;
//     height: 0.8rem;
//     background: #ccc;
//     text-align: center;
//     line-height: 0.8rem;
//     text-decoration: none;
//     border-radius: 0.8rem;
//     display: block;
// }
.release{
    position: fixed;
    width: 0.8rem;
    height: 0.8rem;
    z-index: 100;
    border-radius: 0.8rem;
    display: block;
    bottom: 155px;
    text-align: center;
    right: 0.3rem;
    .iconfont{
      width: 100%;height: 100%!important;
      line-height: 0.72rem!important;
      position: absolute;
      background: #ffffff;
      border-width: 2px!important;
      border-radius: 0.8rem;
    }
    .fabu-font{
        position: relative;
        top: 29px;
        background: red;
        color: #fff;
        font-size: 0.2rem;
        
    }
  }
</style>